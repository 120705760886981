var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "roleMgrForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              queryUrl: _vm.queryUrl,
              submitUrl: _vm.submitUrl,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccessFunc,
            },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _vm.showReturn
                      ? _c("v-button", {
                          attrs: { text: "返回" },
                          on: { click: _vm.returnClick },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "template",
            { slot: "headerSlot" },
            [
              _c("v-button", {
                attrs: { text: "返回" },
                on: { click: _vm.goBack },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "角色信息" } },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.defaultRoleName,
                      expression: "!defaultRoleName",
                    },
                  ],
                  attrs: {
                    label: "角色名称",
                    prop: "roleName",
                    rules: [
                      {
                        required: true,
                        message: "请填写角色名称",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: { placeholder: "请输入角色名称" },
                    model: {
                      value: _vm.form.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleName", $$v)
                      },
                      expression: "form.roleName",
                    },
                  }),
                ],
                1
              ),
              _vm.roleId
                ? _c(
                    "div",
                    [
                      _c("el-form-item", { attrs: { label: "角色类型" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.backData.roleTypeName) +
                            "\n        "
                        ),
                      ]),
                      _vm.isCommonVisible(_vm.backData.roleType)
                        ? _c("el-form-item", { attrs: { label: "是否通用" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.isCommonMap[_vm.backData.isCommon]) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "角色归属", prop: "roleName" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.backData.orgName) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "角色类型",
                            prop: "roleType",
                            rules: [
                              {
                                required: true,
                                message: "请选择角色类型",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.roleTypeOps,
                              disabled:
                                !!_vm.defaultRoleType || _vm.roleTypeDisabled,
                            },
                            on: { change: _vm.roleTypeChange },
                            model: {
                              value: _vm.form.roleType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "roleType", $$v)
                              },
                              expression: "form.roleType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isCommonVisible(_vm.form.roleType)
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否通用",
                                prop: "isCommon",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择是否通用",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.isCommonOps,
                                  disabled: !!_vm.defaultIsCommon,
                                },
                                model: {
                                  value: _vm.form.isCommon,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isCommon", $$v)
                                  },
                                  expression: "form.isCommon",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "角色归属",
                            prop: "orgId",
                            rules: [
                              {
                                required: true,
                                message: "请选择角色归属",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  subjoin: _vm.roleExtraParams,
                                  placeholder: "选择角色归属",
                                  disabled: !!_vm.defaultOrgId,
                                },
                                on: { onChange: _vm.orgChange },
                                model: {
                                  value: _vm.form.orgId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "orgId", $$v)
                                  },
                                  expression: "form.orgId",
                                },
                              },
                              "v-select2",
                              _vm.organizeParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          !(
            _vm.isAdd &&
            (_vm.form.roleType == "102" || _vm.form.roleType == "101") &&
            !_vm.form.orgId
          )
            ? _c(
                "col2-block",
                { attrs: { title: "角色权限" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属一体化",
                        rules: [
                          {
                            required: true,
                            message: "请填写所属一体化",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.platformOps },
                        model: {
                          value: _vm.platform,
                          callback: function ($$v) {
                            _vm.platform = $$v
                          },
                          expression: "platform",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !(
            _vm.isAdd &&
            (_vm.form.roleType == "102" || _vm.form.roleType == "101") &&
            !_vm.form.orgId
          )
            ? _c(
                "col2-block",
                [
                  !_vm.roleId && !_vm.queryCopyId
                    ? _c("role-permissions", {
                        ref: "permissionsRefAdd",
                        attrs: {
                          roleTypeId: _vm.form.roleType,
                          unifyId: _vm.platform,
                          roleDataList: _vm.rolePermissionsList,
                          handleType: "add",
                        },
                      })
                    : _c("role-permissions", {
                        ref: "permissionsRefEdit",
                        attrs: {
                          roleTypeId: _vm.form.roleType,
                          unifyId: _vm.platform,
                          roleDataList: _vm.rolePermissionsList,
                          handleType: _vm.editType,
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
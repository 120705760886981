<template>
  <div class="roleMgrForm-container">
    <form-panel
      ref="formPanel"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccessFunc"
      @update="update"
    >
      <template slot="headerSlot">
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <col2-block title="角色信息">
        <el-form-item v-show="!defaultRoleName" label="角色名称" prop="roleName" :rules="[{ required: true, message: '请填写角色名称', trigger: 'blur' }]">
          <v-input v-model="form.roleName" placeholder="请输入角色名称"></v-input>
        </el-form-item>
        <div v-if="roleId">
          <el-form-item label="角色类型">
            {{backData.roleTypeName}}
          </el-form-item>
          <el-form-item v-if="isCommonVisible(backData.roleType)" label="是否通用">
            {{ isCommonMap[backData.isCommon] }}
          </el-form-item>
          <el-form-item label="角色归属" prop="roleName">
            {{backData.orgName}}
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="角色类型" prop="roleType" :rules="[{ required: true, message: '请选择角色类型', trigger: 'change' }]">
            <v-select v-model="form.roleType" :options="roleTypeOps" :disabled="!!defaultRoleType || roleTypeDisabled" @change="roleTypeChange"></v-select>
          </el-form-item>
          <el-form-item v-if="isCommonVisible(form.roleType)" label="是否通用" prop="isCommon" :rules="[{ required: true, message: '请选择是否通用', trigger: 'change' }]">
            <v-select v-model="form.isCommon" :options="isCommonOps" :disabled="!!defaultIsCommon" />
          </el-form-item>
          <el-form-item label="角色归属" prop="orgId" :rules="[{ required: true, message: '请选择角色归属', trigger: 'change' }]">
            <v-select2 v-model="form.orgId" :subjoin="roleExtraParams" v-bind="organizeParams" placeholder="选择角色归属" :disabled="!!defaultOrgId" @onChange="orgChange"></v-select2>
          </el-form-item>
        </div>
      </col2-block>
      <col2-block title="角色权限" v-if="!(isAdd&&(form.roleType=='102'||form.roleType=='101')&&!form.orgId)">
        <el-form-item label="所属一体化" :rules="[{ required: true, message: '请填写所属一体化', trigger: 'change' }]">
          <v-select v-model="platform" :options="platformOps"></v-select>
        </el-form-item>
      </col2-block>
      <col2-block v-if="!(isAdd&&(form.roleType=='102'||form.roleType=='101')&&!form.orgId)">
        <role-permissions v-if="!roleId && !queryCopyId" ref="permissionsRefAdd" :roleTypeId="form.roleType" :unifyId="platform" :roleDataList="rolePermissionsList" handleType="add"></role-permissions>
        <role-permissions v-else ref="permissionsRefEdit" :roleTypeId="form.roleType" :unifyId="platform" :roleDataList="rolePermissionsList" :handleType="editType"></role-permissions>
      </col2-block>
      <template #saveBeforeSlot>
        <v-button v-if="showReturn" text="返回"  @click="returnClick" />
      </template>
    </form-panel>
  </div>
</template>
<script>
import {
  getOrganizeURL,
  getRoleTypeURL,
  addRoleURL,
  updataRoleURL,
  getRoleDetailURL,
  getViewMenuURL
} from './api'
import { isCommonMap, setIsCommonOps } from './map'
import { Col2Block } from '@/components/bussiness'
import rolePermissions from 'components/bussiness/role-permissions/role-permissions'
import _ from 'lodash'
export default {
  name: 'RoleMgrForm',
  components: {
    Col2Block,
    rolePermissions
  },
  props: {
    setBreadcrumb: {
      type: Boolean,
      default: true
    },
    submitText: String,
    showReturn: {
      type: Boolean,
      default: false
    },
    defaultRoleName: String,
    defaultQueryId: Object,
    defaultRoleType: String,
    defaultIsCommon: Number,
    defaultOrgId: String,
    commonType: Number,
    submitSuccess: Function,
    isSystem: Number, // 创建租户超管角色的时候传1,
    // 新增传true
    newAdd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      queryUrl: getRoleDetailURL,
      queryCopyId: this.$route.query.copyId,
      roleId: this.$route.query.id,
      submitUrl: this.$route.query.id ? updataRoleURL : addRoleURL,
      roleTypeOps: [],
      platformOps: [],
      isCommonOps: setIsCommonOps(),
      isCommonMap,
      submitConfig: {
        submitText: this.submitText
      },
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      platform: undefined,
      form: {
        roleName: '',
        orgName: '',
        orgId: '',
        isCommon: 0,
        roleType: undefined,
        menuIds: '',
        dataObject: ''
      },
      rolePermissionsList: [],
      backData: {},
      roleTypeDisabled: false,
      roleTypeEcho: false,
      editType: 'edit',
      isAdd: false //新增标记位
    }
  },
  watch: {
    defaultOrgId (newValue) {
      this.form.orgId = newValue
    }
  },
  computed: {
    roleExtraParams () {
      let roleType = this.form.roleType
      let isCommon = 0
      if (this.isCommonVisible(roleType)) {
        isCommon = this.form.isCommon
      }
      return {
        roleType,
        isCommon
      }
    },
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  created () {
    if (this.defaultRoleName) {
      this.form.roleName = this.defaultRoleName
    }
    if (this.defaultRoleType) {
      this.form.roleType = this.defaultRoleType
      if (this.newAdd) {
        this.getRoleData()
      }
    }
    if (this.defaultIsCommon) {
      this.form.isCommon = this.defaultIsCommon
    }
    if (this.defaultOrgId) {
      this.form.orgId = this.defaultOrgId
    }
  },
  mounted () {
    let { id, copyId, orgId, roleType, isCommon } = this.$route.query
    if (this.defaultQueryId) {
      id = this.defaultQueryId.id
      copyId = this.defaultQueryId.copyId
      this.roleId = id
      this.queryCopyId = copyId
      this.submitUrl = id ? updataRoleURL : addRoleURL
    }
    if (id !== undefined) {
      if (this.setBreadcrumb) {
        this.$setBreadcrumb('编辑')
      }
      if(roleType=='101'&& isCommon == 0){
        this.$refs.formPanel.getData({ roleId: id,communityId: orgId })
      } else if(roleType=='102'||(roleType=='101'&& isCommon==1)){
        this.$refs.formPanel.getData({ roleId: id,regionId: orgId })
      } else {
        this.$refs.formPanel.getData({ roleId: id })
      }     
    } else if (copyId) {
      if(roleType=='101'&& isCommon == 0){
        this.$refs.formPanel.getData({ roleId: copyId,communityId: orgId })
      } else if(roleType=='102'||(roleType=='101'&& isCommon==1)){
        this.$refs.formPanel.getData({ roleId: copyId,regionId: orgId })
      } else {
        this.$refs.formPanel.getData({ roleId: copyId })
      }
    } else {
      if (this.setBreadcrumb) {
        this.$setBreadcrumb('新增')
        this.isAdd = true
      }
      this.getRoleType()
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
      this.$router.go(-1)
    },
    isCommonVisible (roleType) {
      if (['106', '100', '102'].includes(this.userType)) {
        return ['101', '102'].includes(roleType)
      }
      return false
    },
    // 获取角色类别
    getRoleType () {
      this.$axios.get(getRoleTypeURL).then(res => {
        if (res.status === '100') {
          const { data } = res
          this.roleTypeOps = data.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          })
          !this.form.roleType && (this.form.roleType = data[0].id)
        }
      })
    },
    // 获取菜单数据
    async getRoleData () {
      this.roleTypeDisabled = true
      let params = {allowUserType: this.form.roleType}
      if(this.form.roleType == '102'){
        params = {
          allowUserType: this.form.roleType,
          regionId: this.form.orgId
        }
      } else if(this.form.roleType == '101') {
        params = {
          allowUserType: this.form.roleType,
          communityId: this.form.orgId
        }
      }
      const { data, status } = await this.$axios.get(getViewMenuURL, { params })
      if (status === 100) {
        this.rolePermissionsList = data || []
        let platformOps = []
        if (data) {
          platformOps = data.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          })
        }
        this.platformOps = platformOps
        this.platform = this.platformOps.length > 0 ? this.platformOps[0].value : undefined
      }
      this.roleTypeDisabled = false
    },
    update (data) {
      let { resource, roleInfo } = data
      this.rolePermissionsList = resource
      this.platformOps = resource.map(item => {
        return {
          text: item.name,
          value: item.id
        }
      })
      this.platform = this.platformOps[0].value
      if (this.roleId) {
        this.backData = roleInfo
        this.form = {
          roleName: roleInfo.roleName,
          orgName: roleInfo.orgName,
          menuIds: '',
          dataObject: '',
          roleId: this.roleId,
          roleType: String(roleInfo.roleType)
        }
      }
      if (this.queryCopyId) {
        this.roleTypeEcho = true
        this.form.roleType = String(roleInfo.roleType)
        this.getRoleType()
      }
    },
    submitBefore (data) {
      let permissionsName = this.roleId || this.queryCopyId ? 'permissionsRefEdit' : 'permissionsRefAdd'
      let ids = this.$refs[permissionsName].getResultData()
      data.menuIds = ids
      data.dataObject = data.roleName
      if (this.commonType !== undefined) {
        data['commonType'] = this.commonType
      }
      if (this.isSystem !== undefined) {
        data['isSystem'] = this.isSystem
      }

      let dataObject = `${data.roleName}-${data.orgName}`
      data.dataObject = dataObject

      return true
    },
    submitSuccessFunc () {
      if (this.submitSuccess) {
        this.submitSuccess()
        return false
      }
      return true
    },
    returnClick () {
      this.$emit('returnClick')
    },
    orgChange (val) {
      if (val && val.name) {
        this.form.orgName = val.name
        if (!this.queryCopyId) {
          if(this.form.roleType == '102'||this.form.roleType == '101'){
            this.getRoleData()
          }
        }
      }
    },
    roleTypeChange () {
      console.log('123132123132123123123123123123123123123123123123123')

      if (this.roleTypeEcho) {
        this.roleTypeEcho = false
      } else {
        if (this.queryCopyId) {
          // 复制的时候，切换类型，数据更新，这个需要改变
          this.editType = 'add'
        }
        if(this.form.roleType !== '102'&&this.form.roleType !== '101'){
          this.getRoleData()
        }       
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.roleMgrForm-container {
  height: 100%;
}
</style>

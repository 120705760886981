import { mapHelper } from 'common/utils'

// 是否通用
const isCommon = [
  {
    text: '否',
    value: 0
  },
  {
    text: '是',
    value: 1
  }
]

const {
  map: isCommonMap,
  setOps: setIsCommonOps
} = mapHelper.setMap(isCommon)

// 状态
const level1MenuId = [
  {
    text: '服务-项目中心',
    value: 10
  }, {
    text: '服务-用户中心',
    value: 20
  }, {
    text: '服务-商业中心',
    value: 30
  }, {
    text: '服务-应用中心',
    value: 40
  }, {
    text: '服务-运营中心',
    value: 50
  }, {
    text: '服务-财务中心',
    value: 60
  }, {
    text: '服务-通用中心',
    value: 70
  }, {
    text: '物联网-物联网中心',
    value: 90
  }, {
    text: '数据-数据中心',
    value: 100
  }
]

const {
  map: level1MenuIdMap,
  setOps: setLevel1MenuIdOps
} = mapHelper.setMap(level1MenuId)

export {
  level1MenuIdMap,
  setLevel1MenuIdOps,
  isCommonMap,
  setIsCommonOps
}
